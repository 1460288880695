import { Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, IconButton, MenuItem, Select, TextField } from '@mui/material';
import { Modal, Typography } from "@mui/material";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input } from '@mui/material';
import { Box } from '@mui/material';
import { FormControl } from '@mui/material';
import { InputLabel } from '@mui/material';
import { Stack } from '@mui/material';
import APIManager from '../../../../lib/apiManager';
import { sistema } from '../../../../model/Sistema';
import moment, { isDate } from 'moment';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Delete } from '@mui/icons-material';
import UsuarioCard from '../../../Cliente/components/UsuarioCard';

const EditandoModal = ({ open, setOpen, selectedItem, columns, aceptarMiembro }) => {
  const [username, setUsername] = useState('');
  const [nombres, setNombres] = useState('');
  const [apellidos, setApellidos] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [tiposDeCliente, setTiposDeCliente] = useState([]);
  const [tipoDeCliente, setTipoDeCliente] = useState(null);
  const [tipoDeClienteNombre, setTipoDeClienteNombre] = useState('');
  const [empresa, setEmpresa] = useState(null);
  const [empresaNombre, setEmpresaNombre] = useState('');

  const [editando, setEditando] = useState(false);

  const [nuevoItem, setNuevoItem] = useState(null);
  const [confirmable, setConfirmable] = useState(false);

  const sedes = sistema.espacios;

  const navigate = useNavigate();

  const verificarConfirmable = () => {
    setNuevoItem(Object.assign({}, selectedItem, { username: username, descripcion: descripcion}));
    setConfirmable(!!username && !!descripcion);
  }

  const handleContinuar = () => {
    aceptarMiembro(selectedItem);
    setOpen(false);
  }

  const handleClose = () => {
    setOpen(false);
  }

  const handleCancel = () => {
    handleClose();
  }

  const handleChangeUsername = (event) => {
    setUsername(event.target.value);
  }

  const handleChangeNombres = (event) => {
    setNombres(event.target.value);
  }

  const handleChangeApellidos = (event) => {
    setApellidos(event.target.value);
  }

  const handleChangeDescripcion = (event) => {
    setDescripcion(event.target.value);
  }

  const handleTipoDeCliente = (event) => {
    const tipo = event.target.value;
    setTipoDeClienteNombre(tipo.descripcion);
    setTipoDeCliente(tipo);
  }

  useEffect(() => {
  }, [tipoDeCliente]);

  useEffect(() => {
    if (!tipoDeCliente) {
      if (!!selectedItem && (tiposDeCliente.length > 0)) {
        setTipoDeCliente(tiposDeCliente.find(item => item.tipo === selectedItem.tipoDeCliente));
      }
    }
  }, [selectedItem, tiposDeCliente]);

  useEffect(() => {
    setNombres(selectedItem?.nombres ?? '');
    setApellidos(selectedItem?.apellidos ?? '');
    setUsername(selectedItem?.nombre ?? '');
    setDescripcion(selectedItem?.descripcion ?? '');
    setTipoDeCliente(selectedItem?.tipoDeCliente ?? '');
    setEmpresa(selectedItem?.relacionLaboral?.empresa);
    setEmpresaNombre(selectedItem?.relacionLaboral?.empresa?.nombre);
  }, [selectedItem]);

  useEffect(() => verificarConfirmable(), [username, nombres, apellidos, descripcion]);

  useEffect(() => { sistema.actual = nuevoItem ?? {} }, [nuevoItem]);

  useEffect(() => {
      APIManager.sendRequest('post','ui','getTiposDeCliente', {}, (response) => {
        if (response.success) {
          setTiposDeCliente(response.data);
        }
      })
  }, []);

  return (
    <Dialog
    open={open}
    aria-labelledby="nuevoContrato-dialog-title"
    aria-describedby="nuevoContrato-dialog-description"
  >
      <DialogTitle id="alert-dialog-title">
        Aceptación de miembro pendiente
      </DialogTitle>
      <DialogContent>
{/*         <DialogContentText id="nuevoContrato-dialog-description">
           Datos del usuario
        </DialogContentText>
 */}
        <Grid container spacing={2} >
          <Grid item sx={12}>
            <UsuarioCard usuario={selectedItem} onChange={() => {}} />
          </Grid>
          <Grid item sx={6}>
            <FormControl variant="standard">
              <InputLabel id="tipoDeCliente-label" shrink={true} >Tipo de Cliente</InputLabel>
              <Select
                // labelId="tipoDeCliente-label"
                id="tipoDeCliente-select"
                value={tipoDeCliente}
                onChange={handleTipoDeCliente}
                disabled={!editando}
                label="Tipo de Cliente"
              >
                {tiposDeCliente.map(
                  (tipo => <MenuItem value={tipo.tipo}>{tipo.descripcion}</MenuItem>)
                )}
              </Select>
            </FormControl>
          </Grid>
          {(tipoDeCliente === 'EMPRESA') && <Grid item sx={6}>
            <FormControl variant="standard" sx={{ width: "100%" }}>
              <InputLabel id="empresa-label">Empresa</InputLabel>
              <Input
                labelId="empresa-label"
                id="empresa-select"
                value={empresaNombre}
                onChange={handleChangeUsername}
                disabled={!editando}
                label="Empresa"
              />
            </FormControl>
          </Grid>}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancelar</Button>
        <Button onClick={handleContinuar} disabled={!confirmable}>
          Aceptar
        </Button>
        <Button variant='contained' onClick={handleContinuar}>
          Rechazar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditandoModal;