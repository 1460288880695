import { Button, Checkbox, FormControl, FormControlLabel, Grid, IconButton, Input, InputLabel, Link, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import APIManager from '../../lib/apiManager';
import EditorTest from "../EditorTest";
import WYSIWYGEditor from "../WYSIWYGEditor";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { Stack } from '@mui/material';
import { InsertDriveFile } from "@mui/icons-material";
import { sistema } from '../../model/Sistema';
import EmpresaCard from "./components/EmpresaCard";
import UsuarioCard from "./components/UsuarioCard";
import OnboardingCard from "./components/OnboardingCard";

const Cliente = ({setBotonera, espacios}) => {

  const [cliente, setCliente] = useState(null);
  const [referente, setReferente] = useState(null);
  const [active, setActive] = useState(false);

  const [miembro, setMiembro] = useState(false);
  const [empresa, setEmpresa] = useState(false);

  const [editando, setEditando] = useState(false);

  const [espacio, setEspacio] = useState(null);
  const [membresia, setMembresia] = useState(null);

  const [razonSocial, setRazonSocial] = useState('');
  const [cuit, setCuit] = useState('');
  const [direccion, setDireccion] = useState('');
  const [email, setEmail] = useState('');

  const [tiposDeCliente, setTiposDeCliente] = useState([]);
  const [tipoDeCliente, setTipoDeCliente] = useState(null);
  const [tipoDeClienteNombre, setTipoDeClienteNombre] = useState('');
  const [membresias, setMembresias] = useState([]);

  const [habilitadoParaGuardar, setHabilitadoParaGuardar] = useState(false);

  const [ready, setReady] = useState(false);

  let { clienteId } = useParams();

  const nuevoClienteEmpresa = {
    id: null,
    nombre: '',
    descripcion: '',
    razonSocial: '',
    cuit: '',
    direccion: '',
    email: '',
    active: false,
    type_id: 11,
    cliente_id: null,
    modoDeContratacion: null,
    modo_de_contratacion_id: null,
    servicio: null,
    tipo: 'EMPRESA',
    espacio: sistema.espacio,
    membresia: null,
    referente: {
      id: null,
      nombre: '',
      // razonSocial: 'Nueva Empresa',
      descripcion: '',
      // cuit: '',
      active: false,
      usuarioPrincipal: {
        id: null,
        nombres: '',
        apellidos: '',
        emailDeContacto: '',
        active: false,
      }
    }
  };

  const nuevoClienteFreelancer = {
    id: null,
    nombre: '',
    descripcion: '',
    razonSocial: '',
    cuit: '',
    direccion: '',
    email: '',
    active: false,
    type_id: 24,
    cliente_id: null,
    modoDeContratacion: null,
    modo_de_contratacion_id: null,
    servicio: null,
    tipo: 'FREELANCER',
    espacio: sistema.espacio,
    membresia: null,
    referente: {
      id: null,
      nombres: '',
      apellidos: '',
      emailDeContacto: '',
      active: false,
    }
  }

  const botoneraDeCliente = [
    { title: 'Volver', label: 'Volver', variant: 'outlined', onClick: () => { navigate(-1); } },
    { title: 'Editar', label: 'Editar', variant: (editando ? 'outlined' : 'contained'), visible: !editando, onClick: () => { setEditando(!editando); } },
    { title: 'Guardar', label: 'Guardar', variant: 'contained', visible: editando, disabled: !habilitadoParaGuardar, onClick: () => { handleGuardar(); } },
  ];

  const navigate = useNavigate();

  const editarCliente = () => {
    setEditando(!editando);
  }

  const clienteActualizado = () => {
    const clienteActualizado = { ...cliente, cuit: cuit, razonSocial: razonSocial };
    clienteActualizado.tipo = tipoDeCliente.tipo;
    clienteActualizado.referente = referente;
    clienteActualizado.active = active;
    clienteActualizado.cuit = cuit;
    clienteActualizado.email = email;
    clienteActualizado.direccion = direccion;
    clienteActualizado.razonSocial = razonSocial;
    clienteActualizado.nombre = (!!referente && !!referente.nombre) ? referente.nombre : razonSocial;
    clienteActualizado.descripcion = (!!referente && !!referente.descripcion) ? referente.descripcion : razonSocial;
    clienteActualizado.espacio = espacio;
    clienteActualizado.membresia = membresia;

    return clienteActualizado;

  }

  const guardarCliente = () => {
    const clienteAEnviar = clienteActualizado();

    // APIManager.sendRequest('post', 'clientes', 'modificarCliente', {cliente: cliente}, (response) => {
    APIManager.sendRequest('post', 'clientes', 'modificarCliente', { cliente: clienteAEnviar }, (response) => {
      if (response.success) {
        setEditando(false); // Se guardó y ya no estamos editando
        setCliente(response.data.cliente);
      }
    });

  }

  const handleTipoDeCliente = (event) => {
    const tipo = event.target.value;
    setTipoDeClienteNombre(tipo.descripcion);
    setTipoDeCliente(tipo);
    setHabilitadoParaGuardar(true);
  }

  const handleActive = (event) => {
    setActive(event.target.checked);
    setHabilitadoParaGuardar(true);
  }

  const handleReferente = (referenteActualizado) => {
    if (!!referenteActualizado.usuarioPrincipal === !!referente.usuarioPrincipal) {
      setReferente(referenteActualizado);
      setHabilitadoParaGuardar(true);
    }
  }

  const handleEspacio = (espacioActual) => {
    setEspacio(espacioActual);
    setHabilitadoParaGuardar(true);
  }

  const handleMembresia = (membresiaActual) => {
    setMembresia(membresiaActual);
    setHabilitadoParaGuardar(true);
  }

  const handleRazonSocial = (event) => {
    setRazonSocial(event.target.value);
    setHabilitadoParaGuardar(true);
  }

  const handleCuit = (event) => {
    setCuit(event.target.value);
    setHabilitadoParaGuardar(true);
  }

  const handleDireccion = (event) => {
    setDireccion(event.target.value);
    setHabilitadoParaGuardar(true);
  }

  const handleEmail = (event) => {
    setEmail(event.target.value);
    setHabilitadoParaGuardar(true);
  }

  const handleGuardar = (event) => {
    guardarCliente();
  }

  const handleChangeTipoDeCliente = () => {
    setReady(false);
    let nuevoCliente = null;
    nuevoCliente = clienteFrom(cliente, tipoDeCliente, referente);
    setCliente(nuevoCliente);
  }

  const clienteFrom = (clienteAnterior, nuevoTipoDeCliente, referenteAnterior) => {
    let nuevoCliente = null;
    if (clienteAnterior.tipo !== nuevoTipoDeCliente.tipo) {
      // Debemos cambiar el tipo de cliente
      if (clienteAnterior.tipo === 'EMPRESA') {
        // Si era empresa, el freelancer pasa a ser el usuarioPrincipal
        nuevoCliente = nuevoClienteFreelancer;
        nuevoCliente.referente = referenteAnterior.usuarioPrincipal;
      } else {
        // Si era freelancer, la empresa pasa a tenerlo como usuarioPrincipal
        nuevoCliente = nuevoClienteEmpresa;
        if (!!empresa && (clienteId !== 'nuevo')) {
          // Tomamos la empresa anterior
          nuevoCliente.referente = empresa;
        }
        nuevoCliente.referente.usuarioPrincipal = referenteAnterior;
      }
      nuevoCliente.id = clienteAnterior.id;
    } else {
      nuevoCliente = clienteAnterior;
    }
    nuevoCliente.active = clienteAnterior.active;
    nuevoCliente.cuit = clienteAnterior.cuit;
    nuevoCliente.razonSocial = clienteAnterior.razonSocial;
    nuevoCliente.email = clienteAnterior.email;
    nuevoCliente.direccion = clienteAnterior.direccion;
    return nuevoCliente;

  }

  useEffect(() => {

    if (!!clienteId) {
      if (clienteId === "nuevo") {
        // Es un cliente nuevo
        const nuevoCliente = Object.assign({}, nuevoClienteEmpresa);
        nuevoCliente.espacio = sistema.espacio;
        setCliente(nuevoCliente);
        setEditando(true);
      } else {
        // Vino clienteId, obtenemos el cliente
        const postData = {};
        if (clienteId.indexOf('-') > 0) {
          postData.cliente_id = clienteId;
        } else {
          postData.id = clienteId;
        }
        APIManager.sendRequest('post', 'clientes', 'getCliente', postData, (response) => {
          if (response.success) {
            if (response.data.cliente.tipo === 'EMPRESA') {
              if (!!response.data.cliente.referente.usuarioPrincipal) {
                if (!response.data.cliente.referente.usuarioPrincipal.emailDeContacto && !!response.data.cliente.referente.usuarioPrincipal.contact_email) {
                  response.data.cliente.referente.usuarioPrincipal.emailDeContacto = response.data.cliente.referente.usuarioPrincipal.contact_email;
                }
              } else {
                response.data.cliente.referente.usuarioPrincipal = { ...nuevoClienteEmpresa.referente.usuarioPrincipal };
              }
            } else {
              if (!response.data.cliente.referente.emailDeContacto && !!response.data.cliente.referente.contact_email) {
                response.data.cliente.referente.emailDeContacto = response.data.cliente.referente.contact_email;
              }
            }
            setCliente(response.data.cliente);
          }
        })
      }

      APIManager.sendRequest('post','ui','getTiposDeCliente', {}, (response) => {
        if (response.success) {
          setTiposDeCliente(response.data);
        }
      })


      APIManager.getList('modosDeContratacion', {}, (response) => {
        if (response.success) {
          setMembresias(response.data.listado);
        }
      });

    }


  }, [clienteId]);

  useEffect(() => {
    if (!!cliente) {
      sistema.actual.cliente = cliente;
      setRazonSocial(cliente.razonSocial);
      setCuit(cliente.cuit);
      setEmail(cliente.email);
      setDireccion(cliente.direccion);
      setActive(cliente.active);
      setReferente(cliente.referente);
      const nuevoEspacio = espacios.find((item) => {
        if (!!cliente.espacio) {
          return item.id === cliente.espacio.id;
        } else {
          return item.id === sistema.espacio.id;
        }
      }) ?? cliente.espacio;
      setEspacio(nuevoEspacio);
    }
  }, [cliente]);

  useEffect(() => {
    if (!!cliente && (!!membresias.length > 0)) {
      let membresiaID;
      if (!!cliente.membresia) {
        membresiaID = cliente.membresia.id;
      } else {
        membresiaID = membresias[0].id;
      }
      setMembresia(membresias.find((item) => { return item.id === membresiaID }) ?? cliente.membresia);
    }
  }, [cliente, membresias]);

  useEffect(() => {
    // console.log('useEffect: referente');
    if (!!referente) {
      if (cliente.tipo === 'EMPRESA') {
        // console.log('Empresa:', referente);
        setEmpresa(referente);
        if (!!referente.usuarioPrincipal) {
          setMiembro(referente.usuarioPrincipal);
        }
        if (!email && !!referente.usuarioPrincipal) {
          setEmail(referente.usuarioPrincipal.emailDeContacto);
        }
      } else {
        // console.log('Miembro:', referente);
        setMiembro(referente);
        if (!email && !!referente) {
          setEmail(referente.emailDeContacto);
        }
      }
    }
    setReady(true);
  }, [referente]);

  useEffect(() => {
    if (!tipoDeCliente) {
      if (!!cliente && (tiposDeCliente.length > 0)) {
        setTipoDeCliente(tiposDeCliente.find(item => item.tipo === cliente.tipo));
      }
    }
  }, [cliente, tiposDeCliente]);

  useEffect(() => {
    if (!!tipoDeCliente && (tipoDeCliente.tipo !== cliente.tipo)) {
      handleChangeTipoDeCliente();
    }
  }, [tipoDeCliente]);

  useEffect(() => {
    setBotonera(botoneraDeCliente);
  }, [habilitadoParaGuardar, referente, active, editando, tipoDeCliente, membresia, espacio, razonSocial, cuit, email, direccion]);

  useEffect(() => {
    Window.sistema = sistema;
  }, []);

  return (
    <Paper sx={{ padding: '14px' }}>
      {!!cliente
        ? <Grid container spacing={2}>
          <Grid item xs={12} sm={2} >
            <FormControl variant="standard">
              <InputLabel id="tipoDeCliente-label" shrink={true} >Tipo de Cliente</InputLabel>
              <Select
                // labelId="tipoDeCliente-label"
                id="tipoDeCliente-select"
                value={tipoDeCliente}
                onChange={handleTipoDeCliente}
                label="Tipo de Cliente"
                disabled={!editando && (clienteId !== 'nuevo')}
              >
                {tiposDeCliente.map(
                  (tipo => <MenuItem value={tipo}>{tipo.descripcion}</MenuItem>)
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl variant="standard" fullWidth>
              <InputLabel id="razonSocial-label" >Razon Social</InputLabel>
              <Input
                disabled={!editando}
                labelId="razonSocial-label"
                id="razonSocial-input"
                value={razonSocial}
                onChange={handleRazonSocial}
                required
              >
              </Input>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2}>
            <FormControl variant="standard">
              <InputLabel id="cuit-label" >CUIT</InputLabel>
              <Input
                disabled={!editando}
                labelId="cuit-label"
                id="cuit-input"
                value={cuit}
                onChange={handleCuit}
                required
              >
              </Input>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2} >
            <FormControlLabel label="Activo" control={
              <Checkbox disabled={!editando} checked={active} onChange={handleActive} />
            } />
          </Grid>
          <Grid item xs={12} sm={2}>
            <FormControl variant="standard">
              <InputLabel id="id-label" >ID</InputLabel>
              <Input
                disabled={true}
                labelId="id-label"
                id="id-input"
                value={((!!cliente && !!cliente.id) ? cliente.id : '') + (!!cliente && !!cliente.id && !!cliente.cliente_id ? ' :: ' : '') + (!!cliente && !!cliente.cliente_id ? cliente.cliente_id : '')}
              >
              </Input>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="standard" fullWidth>
              <InputLabel id="cuit-label" >Dirección postal</InputLabel>
              <Input
                disabled={!editando}
                labelId="direccion-label"
                id="direccion-input"
                value={direccion}
                onChange={handleDireccion}
                required
              >
              </Input>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl variant="standard" fullWidth>
              <InputLabel id="email-label" >Email de contacto</InputLabel>
              <Input
                disabled={!editando}
                labelId="email-label"
                id="email-input"
                value={email}
                onChange={handleEmail}
                required
              >
              </Input>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            {!!tipoDeCliente && ((tipoDeCliente.tipo === 'EMPRESA')
            ? !!empresa && !!ready && <EmpresaCard empresa={empresa} onChange={handleReferente} enabled={editando} />
            : !!miembro && !!ready && <UsuarioCard usuario={miembro} raised={true} onChange={handleReferente} enabled={editando} />)}
          </Grid>
          <Grid item xs={12}>
            {!!miembro && <OnboardingCard espacios={espacios} membresias={membresias} membresia={membresia} espacio={espacio} onChangeMembresia={handleMembresia} onChangeEspacio={handleEspacio} enabled={editando} />}
          </Grid>
        </Grid>
        : <>{ 'No hay datos del cliente' }</>
      }
    </Paper>
  )
}

export default Cliente;